@import 'base';

label.error {
  color: red;
  font-size: 12px !important;
  display: none !important;
}

.zp-lds-ring {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
  margin: 0 auto;
}

.zp-lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 19px;
  height: 19px;
  margin: 2px;
  border: 2px solid #334455;
  border-radius: 50%;
  -webkit-animation: zp-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: zp-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #334455 transparent transparent transparent;
}

.zp-lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}

.zp-lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.zp-lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

@-webkit-keyframes zp-lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes zp-lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.zp-padding-modal-form{
  padding: 40px;
}

#zp-vende-nosotros-modal{
  padding-right: 0px !important;
}

.zp-vende-img img{
    max-height: 330px;
}

.zp-gray{
  color: $fourth-color;
  font-size: 16px;
  line-height: 1;
}

.btn-primary.disabled, .btn-primary:disabled{
    background-color: $main-color;
    border-color: $main-color;
}

.btn-primary{
    background-color: $main-color;
    border-color: $main-color;
}

.zp-no-padding{
    padding: 0
}
.zp-login-modal-container{

}
.zp-login-modal-form{
  margin-top: 20px;
}
.modal-dialog .close{
    position: absolute;
    top: 5px;
    right: 5px;
}
.zp-label-form{
  font-size: 14px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-weight: 400 !important;
  color: #5A5A5A !important;
}
.zp-label-modal-h2{
  color: $new-blue-color;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -1px;
  margin-bottom: 20px;
  margin-top: 31px;
}
.zp-form-login-container{
  padding: 0px 40px;
}
.zp-modal-vende-label{
  color: $fourth-color;
  font-size: 12px;
}
.zp-vende-btn{
  margin-top: 10px;
  margin-bottom: 20px;
}
.zp-vende-img-modal{
  min-height: 550px;
  background-image: url("/storage/imgs/otros/stgo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.zp-btn-modal-show{
  font-weight: 700;
}

.zp-vende-img-container{
  min-height: 550px;
  opacity: 0;
}
body{
    padding-right: 0 !important;
    overflow: auto !important;
}
.zp-fix-margin .form-group{
      margin-bottom: 0rem;
      margin-top: 1rem;
}
.zp-error-data {
    border-radius: 4px;
    font-weight: 300;
    color: red;
    font-size: 12px;
}

.zp-errors-border-bottom .form-control:focus{
  border-bottom: 1px solid #D0D0D0;
}
.zp-errors-border-bottom .form-control{
  border-bottom: 1px solid #D0D0D0;
}
.zp-errors-border-bottom .zp-form-error{
  border-bottom: 1px solid #ff0000;
}
.zp-errors-border-bottom .zp-form-error:focus{
  border-bottom: 1px solid #ff0000;
}

.zp-errors-border-all .form-control:focus{
  border: 1px solid #D0D0D0;
}
.zp-errors-border-all .form-control{
  border: 1px solid #D0D0D0;
}

.zp-errors-border-all .zp-form-error{
  border: 1px solid #ff0000;
}
.zp-errors-border-all .zp-form-error:focus{
  border: 1px solid #ff0000;
}

.zp-error-bag{
  margin-top: 10px;
  margin-bottom: 10px;
}

.zp-texto-gris{
    color: #5A5A5A;
    font-weight: 400;
    font-size: 15px;
}

.zp-divisor-recuperar{
  margin-bottom: 40px;
}

.zp-fix-margin .fa-exclamation-circle{
  margin-right: 3px;
}

.zp-error-span{
  font-size: 13px;
}

#form-cotizacion .zp-error-span{
  color: red;
  font-weight: 100;
}
.zp-cotizador-input-container .form-control:focus{
  border-color: #A0A0A0;
}

.zp-cotizador-input-container .form-control.form-error:focus{
  border-color: red;
}

.zp-cotizador-input-container .fa-exclamation-circle{
  margin-right: 5px;
}
.zp-auth-change_password{
  display: none;
}

.zp-select-form-error div[role="combobox"],
input.zp-select-form-error{
  border-color: red;
}
.zp-label-fix{
    margin-bottom: 0rem;
    height: 20px;
}
.zp-select-fix{
    margin-bottom: 15px;
}
.zp-ce-title{
   color: $fourth-color;
}
.zp-bci{
  background-color: transparent;
  color: $main-color;
  font-weight: 600;
}
.zp-bci:hover{
  background-color: transparent;
  color: $main-color;
}

.zp-ce-table th:first-child, .zp-ce-table td:first-child{
  text-align: left;
  border-left: 1px solid $light-gray;
}

.zp-ce-table th:last-child, .zp-ce-table td:last-child{

}

.zp-ce-table th, .zp-ce-table td{
  border-right: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;
  text-align: center;
}
.zp-ce-table tbody{
  color: $third-color;
}

.zp-cotizacion-secondary{
  margin-bottom: 0;
  float:right;
  color: $third-color;
}

.zp-title{
  color: $fourth-color;
}
.zp-gray-table{
  color: $fourth-color;
  margin-top: 30px;
  float:right;
}
.zp-bci img{

    max-height: 20px;
    margin-right: 10px;
    top: -2px;
    position: relative;
}

.form-control:focus
{
  outline: none;
  box-shadow: none;
}

/*Vue select*/
.vs__dropdown-option {
  color: $fourth-color;
}
.vs__dropdown-option--highlight {
  color: $fourth-color;
  background-color: $main-color !important;
}

.zp-image-login-mshow{
  display: none;
}
.zp-image-login-mshow .close{
  right: 10px;
}

.page-item {
  padding: 0 10px 0 10px;
}

.page-item .page-link{
  background-color: white;
  border-color: #aaa;
  border-radius: 50px;
  padding: 20px;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa;
}

.page-item.active .page-link{
    background-color: $main-color;
    border-color: $main-color;
    border-radius: 25px;
}
.page-link:hover,.page-link:focus{
    color: $fourth-color;
    box-shadow: none;
}
.page-link{
    color: $fourth-color;
}
.paginator{
  width: 100%;
}

.modal-backdrop{
  width: 100%;
  height: 100%;
}
.zp-paginador{
  height: 38px;
  width: 100%;
} 
.zp-paginador.zp-administrador-margen{
  padding-right: 50px;
} 
 
.zp-paginador .pagination{
  float: right;
}
.zp-paginador-derecha{
  padding-right: 15px;
}
.zp-paginador-derecha .pagination.row{
  float: right;
  margin: 0px;
}

.zp-checkbox-contenedor .fa-check-square,
.zp-checkbox-contenedor .fa-square{
  font-size: 18px !important;
}

.zp-inicio-sesion-checkbox{  
    top: -4px;
    position: relative;
    color: $fourth-color;
}

.zp-inicio-sesion-checkbox .fa-check-square,
.zp-inicio-sesion-checkbox .fa-square
{
  font-size: 14px;
}

.zp-excel-error_fila{
  color: red;
  font-size: 15px;
}
.zp-excel-bien_fila{
  color: green;
  font-size: 15px;
}

@media screen and (min-width: 0px) and (max-width: 840px){
  .modal.show .modal-dialog{
    padding-right: 10px;
    padding-left: 10px;
  }
  .zp-label-modal-h2{
    margin-bottom: 0px;
    margin-top: 0px;
    line-height: 24px;
  }
}

@media (min-width: 1px) and (max-width: 768px){
    .zp-image-login{
        display: none;
    }
    .zp-image-login-mshow{
        display: block;
    }
    .zp-form-login-container{
      padding: 30px !important;
    }
    .zp-paginador.zp-administrador-margen{
      padding-right: 35px;
    } 
}
